export const removeNotificationById = {
  removeNotificationById: function(state, notificationId) {
    state.notifications = state.notifications.filter(notification => {
      return notification.id != notificationId;
    });
  }
};

export const removeLastNotification = {
  removeLastNotification: function(state) {
    state.notifications.pop();
  }
};
