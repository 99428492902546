import gql from 'graphql-tag';

export const MARK_NOTIFICATIONS_READ_ALL = gql `
  mutation MarkNotificationReadAllMutation($input : MarkReadAllWebNotificationInput!){
    markReadAllWebNotification(input : $input){
      meta_data {
        value
      }
    }
  }
`;
