export const updateAllNotificationsReadStatus = {
  updateAllNotificationsReadStatus: function(state, unReadNotificationsCount) {
    state.unreadCount = unReadNotificationsCount;
    state.notifications.map((notification) => {
      notification.is_read = true;
    });
  }
};

export const updateNotificationReadStatus = {
  updateNotificationReadStatus: function(state, notificationId) {
    state.notifications.map((notification) => {
      if (notification.id == notificationId) {
        notification.is_read = true;
      }
    });
  }
};
