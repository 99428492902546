import { WEB_NOTIFICATIONS_BATCH_QUERY } from 'queries/notifications/batch_notifications_query';
import { isResponseEmpty } from 'concerns/dataUtilities';

const WIDGET_NOTIFCATIONS_LIMIT = 7;
const DEFAULT_PAGE_NUMBER = 0;

export const fetchNotifications = {
  fetchNotifications({ commit }, { apollo, data }) {
    return new Promise((res, rej) => {
      apollo.query({
          query: WEB_NOTIFICATIONS_BATCH_QUERY,
          variables: {
            limit: data.limit || WIDGET_NOTIFCATIONS_LIMIT,
            page_number: data.page_number || DEFAULT_PAGE_NUMBER,
            entity_name : ' '
          },
        })
        .then((response) => {
          if (isResponseEmpty(response)) {
            commit('setNotifications', []);
          } else {
            const { web_notifications_metadata, web_notifications } = response.data;
            commit('setNotifications', web_notifications);
            commit('setWebNotificationsMetadata', web_notifications_metadata);
          }
          res();
        });
    });
  },
};
