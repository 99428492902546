import gql from 'graphql-tag';

export const WEB_NOTIFICATIONS_BATCH_QUERY = gql`
  query webNotificationsBatchQuery($limit: Int!, $page_number: Int!, $entity_name: String) {
    web_notifications(limit: $limit, page_number: $page_number, entity_name: $entity_name) {
      id
      content
      entity_url
      is_entity_modal_previewable
      is_read
      profile_picture
      icon_class_name
      created_at
    }
    web_notifications_metadata(entity_name: $entity_name) {
      field_name
      value
    }
  }
`;
