export const EMAIL_REGEX = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const isResponseEmpty = response => {
  return !Object.keys(response.data).length;
};

export const sortArrayUsingProp = (inArr, prop, dir = 'ASC') => {
  const direction = dir === 'ASC' ? 1 : -1;
  return inArr.sort((a, b) => {
    if (a[prop] > b[prop]) {
      return direction;
    } else if (a[prop] < b[prop]) {
      return -1 * direction;
    } else {
      return 0;
    }
  });
};

export const sortArrayDeeplyUsingProp = (inArr, prop, deepProp = {}, dir = 'ASC') => {
  const direction = dir === 'ASC' ? 1 : -1;
  inArr.map(ele => {
    deepSort(ele, prop, deepProp, direction);
  });
  return sortArrayUsingProp(inArr, prop);
};

const deepSort = (arrObj, prop, deepObj, dir = 'ASC') => {
  extractKeys(deepObj).map(key => {
    arrObj[key] = [...sortArrayUsingProp(arrObj[key], prop, dir)];
    console.log(arrObj[key]);
    if (isAnObject(deepObj[key])) {
      arrObj[key].map(subOption => {
        if (extractKeys(subOption).includes(extractKeys(deepObj[key])[0])) {
          deepSort(subOption, prop, deepObj[key]);
        }
      });
    }
  });
};

export const normalizeFiltersViaOptionCount = (appliedFilters) => {
  return appliedFilters.filter(filterObj => {
    var presentFilters = filterObj.options.filter(option => option.count)
    return presentFilters.length;
  })
}

export const getNormalizedFilterHash = (appliedFilters) => {
  let normalizedFilter = {};
  extractKeys(appliedFilters).map(key => {
    const filterControlVal = appliedFilters[key];
    if (filterControlVal.length) {
      normalizedFilter[key] = filterControlVal;
    } else if (isAnObject(filterControlVal)) {
      let groupObj = {};
      extractKeys(filterControlVal).map(groupKey => {
        if (filterControlVal[groupKey].length) {
          groupObj[groupKey] = filterControlVal[groupKey];
        }
      });
      normalizedFilter[key] = groupObj;
    }
  });
  return JSON.stringify(normalizedFilter);
}

export const extractValueFromQueryUrl = (key) => {
  let extractedQueryString = window.location.search ? decodeURIComponent(window.location.search).slice(1).split('&').find(e => e.includes(key)) : '';
  return extractedQueryString ? extractedQueryString.split('=')[1] : '';
}

export const isAnObject = obj => {
  return obj.constructor === Object;
};

export const isAnArray = arr => {
  return Array.isArray(arr);
};

export const extractKeys = obj => {
  return Object.keys(obj);
};

export const extractPropsObj = (obj, prop) => {
  return obj.map(ele => ele[prop]);
};

export const isArrayEqual = (array_one, array_two) => {
  if (!array_two){
    return false;
  }

  if (array_one.length != array_two.length){
    return false;
  }

  for (var i = 0, l= array_one.length; i < l; i++) {
    if (isAnArray(array_one[i]) && isAnArray(array_two[i])) {
      if (!array_one[i].equals(array_two[i])) {
        return false;
      }
    }
    else if (array_one[i] != array_two[i]) {
      return false;
    }
  }
  return true;
};

export const findObject = (arrObj, propToFind, keyToMatch) => {
  return arrObj.find(ele => ele[propToFind] == keyToMatch);
};
