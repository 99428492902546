import gql from 'graphql-tag';

export const MARK_NOTIFICATION_READ = gql `
  mutation MarkNotificationReadMutation($input : MarkReadWebNotificationInput!){
    markReadWebNotification(input : $input){
      web_notification{
        id
        is_read
      }
      meta_data {
        value
        field_name
      }
    }
  }
`;
