import { MARK_NOTIFICATION_READ } from 'queries/notifications/mark_notification_read_query';
import { MARK_NOTIFICATIONS_READ_ALL } from 'queries/notifications/mark_notification_allread_query';

export const markAllNotificationsAsRead = {
  markAllNotificationsAsRead: function ({ commit }, apollo) {
    apollo.mutate({
      mutation: MARK_NOTIFICATIONS_READ_ALL,
      variables: {
        input: {}
      }
    }).then(res => {
      const remainingUnreadNotifications = parseInt(res.data.markReadAllWebNotification.meta_data.value, 10);
      commit('updateAllNotificationsReadStatus', remainingUnreadNotifications);
    })
  }
};

export const markNotificationAsRead = {
  markNotificationAsRead({ commit }, { apollo, data }) {
    return new Promise((res, rej) => {
      apollo.mutate({
        mutation: MARK_NOTIFICATION_READ,
        variables: {
          input: { id: data.notificationId },
          hideLoader:true
        }
      }).then(response => {
        commit('updateNotificationReadStatus', data.notificationId);
        commit('setUnreadNotifications', parseInt(response.data.markReadWebNotification.meta_data.value, 10));
        res();
      })
    })
  }
};
