export const State = {
  notifications: [],
  unreadCount: 0
};

export const Getters = {
  getNotifications (state) {
    return state.notifications;
  },
  getUnreadCount (state) {
    return state.unreadCount;
  }
};
