import { fetchNotifications } from '../actions/fetch_notifications';
import { checkForUpdate } from '../actions/check_for_update';
import { markAllNotificationsAsRead, markNotificationAsRead } from '../actions/mark_notification_read';
import { setNotifications } from "../mutations/set_notifications";
import { setUnreadNotifications } from '../mutations/set_unread_count';
import { addNotification } from '../mutations/add_notification';
import { removeNotificationById, removeLastNotification } from '../mutations/remove_notification';
import { updateAllNotificationsReadStatus, updateNotificationReadStatus } from '../mutations/update_notification_status';
import { setWebNotificationsMetadata } from '../mutations/set_notifications_metadata';
import { State, Getters } from "../state";
import * as deepmerge from 'deepmerge';

const Actions = deepmerge.all([fetchNotifications,checkForUpdate, markAllNotificationsAsRead, markNotificationAsRead]);
const Mutations = deepmerge.all([
  setNotifications,
  setUnreadNotifications,
  updateAllNotificationsReadStatus,
  setWebNotificationsMetadata,
  updateNotificationReadStatus,
  addNotification,
  removeNotificationById,
  removeLastNotification
]);

export default {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
