export const checkForUpdate = {
  checkForUpdate: function ({ commit, state }, { response, rootRef }) {
    const { message } = response;
    if (message.event == 'read') {
      if(message.notification_id == 'all'){
        return commit('updateAllNotificationsReadStatus', message.totalUnreadCount);
      }
      commit('updateNotificationReadStatus', message.notification_id);
    } else if (message.event == 'destroy') {
      commit('removeNotificationById', message.notification_id);
    } else {
      const notificationData = JSON.parse(message.notification_content);
      rootRef.showHtmlAlert({name: 'notification-list-item', data: notificationData});
      commit('addNotification', notificationData);
      if(state.notifications.length > 7){
        commit('removeLastNotification');
      }
    }
    commit('setUnreadNotifications', message.totalUnreadCount);
  }
};
